import { requests } from '@/plugins/Amplify'

import {
  STATUS_ERROR,
  STATUS_SAVED,
  STATUS_LOADED,
  STATUS_LOADING
} from '@/constants'

const ALBUM_ERROR = 'ALBUM_ERROR'
const ALBUMS_LOADING = 'ALBUMS_LOADING'
const ALBUM_SAVED_SUCCESS = 'ALBUM_SAVED_SUCCESS'
const ALBUM_DELETED_SUCCESS = 'ALBUM_DELETED_SUCCESS'
const ALBUM_LOADED_SUCCESS = 'ALBUM_LOADED_SUCCESS'
const ALBUM_LIST_LOADED_SUCCESS = 'ALBUM_LIST_LOADED_SUCCESS'

const resetStore = () => ({
  row: {},
  rows: [],
  count: 0,
  params: null,
  status: null
})

export default {
  state: resetStore(),

  actions: {
    loadAlbums: async ({ commit }, params) => {
      commit(ALBUMS_LOADING, params)

      try {
        const data = await requests.getAlbums({ ...params, limit: 12 })
        commit(ALBUM_LIST_LOADED_SUCCESS, data)
      } catch (err) {
        console.log(err)
        commit(ALBUM_ERROR, err)
      }
    },

    saveAlbum: async ({ commit }, payload) => {
      commit(ALBUMS_LOADING)

      try {
        const requestSave = payload.id ? requests.putAlbum(payload.id, payload) : requests.postAlbum(payload)
        const albumSaved = await requestSave
        commit(ALBUM_SAVED_SUCCESS, albumSaved)
      } catch (err) {
        console.log(err)
        commit(ALBUM_ERROR, err)
      }
    },

    loadAlbum: async ({ commit }, { id }) => {
      commit(ALBUMS_LOADING)

      try {
        const album = await requests.getAlbum(id)
        commit(ALBUM_LOADED_SUCCESS, album)
      } catch (err) {
        console.log(err)
        commit(ALBUM_ERROR, err)
      }
    },

    error: ({ commit }) => {
      commit(ALBUM_ERROR, 'Erro ao salvar álbum')
    },

    removeAlbum: async ({ commit }, albumId) => {
      try {
        commit(ALBUMS_LOADING)
        const data = await requests.deleteAlbum(albumId)
        commit(ALBUM_DELETED_SUCCESS, data)
      } catch (error) {
        commit(ALBUM_ERROR, error)
      }
    }
  },

  mutations: {
    [ALBUMS_LOADING]: (state, params) => {
      const newState = { status: STATUS_LOADING }
      if (params) newState.params = params
      Object.assign(state, newState)
    },

    [ALBUM_LOADED_SUCCESS]: (state, album) => {
      Object.assign(state, { row: album, status: STATUS_LOADED })
    },

    [ALBUM_LIST_LOADED_SUCCESS]: (state, data) => {
      const { rows, count } = data
      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [ALBUM_SAVED_SUCCESS]: (state, row) => {
      Object.assign(state, { row, status: STATUS_SAVED })
    },

    [ALBUM_DELETED_SUCCESS]: (state, data) => {
      const rows = state.rows.filter(({ id }) => id !== data.at(0).id)
      Object.assign(state, { rows, status: STATUS_SAVED })
    },

    [ALBUM_ERROR]: (state, err) => {
      console.log(err)
      Object.assign(state, { status: STATUS_ERROR })
    }
  }
}
