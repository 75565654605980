<template>
  <router-view />
</template>

<script>
import PageBus from '@/components/page/PageBus'
import { requests } from '@/plugins/Amplify'

export default {
  mounted () {
    document.addEventListener('click', this.clickAudioLink)
  },

  beforeDestroy () {
    document.removeEventListener('click', this.clickAudioLink)
  },

  methods: {
    async clickAudioLink (evt) {
      const target = evt.target.closest('a')
      const href = target?.attributes?.href?.value

      if (!href) return
      if (!href.includes('/reproduzir/')) return

      evt.preventDefault()
      const audioId = Number(href.split('/reproduzir/').pop())
      if (isNaN(audioId)) return

      const data = await requests.getAudios({ id: audioId })
      if (!data?.rows?.length) return
      const audio = data.rows.at(0)

      PageBus.$emit('audioPlay', {
        id: audio.id,
        data: audio.data,
        album: audio.albums.at(0)?.nome ?? '',
        grau: audio.grau,
        nucleo: audio.nucleo,
        orador: audio.orador,
        sessaoId: audio.sessaoId,
        sessao: audio.sessao,
        duracao: audio.duracao,
        assunto: audio.assunto,
        dirigente: audio.dirigente
      })
    }
  }
}

</script>
