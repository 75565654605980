<template>
  <v-navigation-drawer
    ref="navigation"
    app
    clipped
    permanent
    expand-on-hover
    v-model="drawer"
    :width="widthDrawer"
    @transitionend="handlerLog"
  >
    <v-list-item
      dense
      class="px-2 py-1 mt-0"
      style="border-bottom: 1px solid #ddd"
    >
      <v-list-item-avatar class="ma-0">
        <v-icon size="medium">
          mdi-weather-night
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-title>
        <span v-if="user">{{ user }}</span>
        <v-list-item-subtitle>Bem vindo!</v-list-item-subtitle>
      </v-list-item-title>
    </v-list-item>

    <div
      v-for="(item, index) in menu"
      :key="`item-${index}`"
    >
      <v-list-item
        dense
        class="px-2 py-1 mt-0"
        style="border-bottom: 1px solid #ddd"
        :to="item.route ? { name: item.route } : undefined"
        @click="toggleMenu(index)"
      >
        <v-list-item-avatar
          v-if="item.icon"
          class="ma-0"
        >
          <v-icon
            size="medium"
            :color="item.color"
          >
            {{ item.icon }}
          </v-icon>
        </v-list-item-avatar>

        <v-list-item-title
          v-if="item.title"
          :style="`color: ${item.color}`"
        >
          {{ item.title.translate() }}

          <v-list-item-subtitle
            v-if="item.label"
            :style="`color: ${item.color}`"
          >
            {{ item.label.translate() }}
          </v-list-item-subtitle>
        </v-list-item-title>

        <v-list-item-action v-if="item.menu">
          <v-icon
            size="sm"
            v-if="showMenu[index]"
          >
            mdi-menu-up
          </v-icon>
          <v-icon
            size="sm"
            v-else
          >
            mdi-menu-down
          </v-icon>
        </v-list-item-action>
      </v-list-item>

      <template v-if="showMenu[index]">
        <div
          v-for="(subitem, subindex) in item.menu"
          :key="`item-${subindex}`"
        >
          <v-list-item
            dense
            class="px-2 py-1 mt-0"
            style="border-bottom: 1px solid #ddd"
            :to="subitem.route ? { name: subitem.route } : undefined"
          >
            <v-list-item-avatar
              v-if="subitem.icon"
              class="ma-0"
            >
              <v-icon size="medium">
                {{ subitem.icon }}
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-title v-if="subitem.title">
              {{ subitem.title.translate() }}

              <v-list-item-subtitle v-if="subitem.label">
                {{ subitem.label.translate() }}
              </v-list-item-subtitle>
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </div>
  </v-navigation-drawer>
</template>

<script>
import menuConfig from '@/components/menuConfig'

import {
  ROUTE_AUDIOS_NEW,
  ROUTE_LEARN_PAGES,
  ROUTE_AUDIOS_EXAM,
  ROUTE_ALBUMS_LIST,
  ROUTE_USERS_SEARCH,
  ROUTE_AUDIOS_REVIEW,
  ROUTE_AUDIOS_REVIEW2,
  ROUTE_AUDIOS_APPROVING,
  ROUTE_DASHBOARD_CHARTS
} from '@/constants'

const onlyAdmin = [
  ROUTE_AUDIOS_NEW,
  ROUTE_ALBUMS_LIST,
  ROUTE_USERS_SEARCH,
  ROUTE_DASHBOARD_CHARTS
]

export default {
  props: {
    session: {}
  },

  data () {
    return {
      drawer: true,
      user: null,
      menu: [],
      widthDrawer: 65,
      showMenu: {}
    }
  },

  mounted () {
    if (this.session) this.loadSession(this.session)
  },

  methods: {
    handlerLog () {
      const isOpen = this.$refs.navigation.$el.classList.contains('v-navigation-drawer--is-mouseover')
      this.widthDrawer = isOpen ? 165 : 65
    },

    loadSession (session) {
      const { administrador, revisor, supervisor, grau, nome } = session
      const nameParts = String(nome || '').split(' ')
      const lastIndex = nameParts.length - 1
      const firstName = nameParts[0] ?? ''
      const lastNameName = nameParts[lastIndex] !== firstName ? nameParts[lastIndex] : ''
      const grauName = grau === 'QM' ? 'M.' : (['CDC', 'CC'].includes(grau) ? 'C.' : '')
      this.user = `${grauName} ${nameParts[0]} ${lastNameName}`.trim()

      if (administrador) {
        this.menu = menuConfig
        return
      }

      this.menu = menuConfig.filter(({ route }) => {
        if (onlyAdmin.includes(route)) return false
        if (route === ROUTE_LEARN_PAGES && !supervisor) return false
        if (route === ROUTE_AUDIOS_REVIEW && !revisor && !supervisor) return false
        if (route === ROUTE_AUDIOS_REVIEW2 && !revisor && !supervisor) return false
        if (route === ROUTE_AUDIOS_APPROVING && !supervisor) return false
        if (route === ROUTE_AUDIOS_EXAM && !supervisor) return false
        return true
      })
    },

    toggleMenu (index) {
      if (!this.menu[index].menu) return
      const isOpen = !this.showMenu[index]
      this.showMenu = { ...this.showMenu, [index]: isOpen }
    }
  },

  watch: {
    session (session) {
      this.loadSession(session)
    }
  }
}
</script>
