import router from '@/router'
import { getToken } from '@/utils'

import {
  ROUTE_AUTH_MFA,
  ROUTE_AUDIOS_APPROVED,
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_INVITE,
  ROUTE_AUTH_FORCE_NEW_PASSWORD,
  ROUTE_AUTH_CREATE,
  ROUTE_AUTH_RECOVERY,
  ROUTE_AUTH_FORGOT,
  ROUTE_REMOVE_ACCOUNT,
  MUTATION_ROUTE
} from '@/constants'

export default () => async (store) => {
  router.beforeEach(async (to, from, next) => {
    const token = await getToken()
    const { fullPath: path, ...payload } = to
    const publicAccess = [
      ROUTE_REMOVE_ACCOUNT,
      ROUTE_AUTH_MFA,
      ROUTE_AUTH_LOGIN,
      ROUTE_AUTH_FORGOT,
      ROUTE_AUTH_CREATE,
      ROUTE_AUTH_INVITE,
      ROUTE_AUTH_RECOVERY,
      ROUTE_AUTH_FORCE_NEW_PASSWORD
    ].includes(payload.name)

    const removeAccount = payload.name === ROUTE_REMOVE_ACCOUNT

    const forceLogin = !publicAccess && !token
    if (forceLogin) return next({ name: ROUTE_AUTH_LOGIN })

    const forceDashboard = token && publicAccess && !removeAccount
    if (forceDashboard) return next({ name: ROUTE_AUDIOS_APPROVED })

    const checkUserCognito = [ROUTE_AUTH_MFA, ROUTE_AUTH_FORCE_NEW_PASSWORD].includes(payload.name)
    if (checkUserCognito && !store.state.auth.userCognito) return next({ name: ROUTE_AUTH_LOGIN })
    if (payload.name === ROUTE_AUTH_INVITE && !payload.query.username) return next({ name: ROUTE_AUTH_LOGIN })

    store.commit(MUTATION_ROUTE, { from: { ...from }, to: { ...to } })
    next()
  })
}
