import {
  ROUTE_AUDIOS_NEW,
  ROUTE_UPDATES_LIST,
  ROUTE_AUDIOS_EXAM,
  ROUTE_LEARN_PAGES,
  ROUTE_USERS_SEARCH,
  ROUTE_ALBUMS_LIST,
  ROUTE_AUDIOS_REVIEW,
  ROUTE_AUDIOS_REVIEW2,
  ROUTE_AUDIOS_APPROVED,
  ROUTE_AUDIOS_APPROVING,
  ROUTE_DASHBOARD_CHARTS,
  ROUTE_NOTIFICATIONS
} from '@/constants'

export default [{
  icon: 'mdi-view-dashboard',
  color: '#5690ca',
  title: 'title.dashboardTitle',
  route: ROUTE_DASHBOARD_CHARTS
}, {
  icon: 'mdi-shield-star-outline',
  color: '#5690ca',
  title: 'title.audiosApprovedTitle',
  label: 'title.audiosApprovedLabel',
  route: ROUTE_AUDIOS_APPROVED
}, {
  icon: 'mdi-star-outline',
  color: '#0094d9',
  title: 'title.audiosApprovingTitle',
  label: 'title.audiosApprovingLabel',
  route: ROUTE_AUDIOS_APPROVING
}, {
  icon: 'mdi-account',
  color: '#d88a00',
  title: 'title.audiosReview2Title',
  label: 'title.audiosReview2Label',
  route: ROUTE_AUDIOS_REVIEW2
}, {
  icon: 'mdi-home',
  color: '#449446',
  title: 'title.audiosReviewTitle',
  label: 'title.audiosReviewLabel',
  route: ROUTE_AUDIOS_REVIEW
}, {
  icon: 'mdi-magnify',
  color: '#de789e',
  title: 'title.audiosExamTitle',
  label: 'title.audiosExamLabel',
  route: ROUTE_AUDIOS_EXAM
}, {
  icon: 'mdi-disc-player',
  title: 'title.newAudiosTitle',
  label: 'title.newAudiosLabel',
  route: ROUTE_AUDIOS_NEW
}, {
  icon: 'mdi-music-circle-outline',
  title: 'title.listAlbums',
  route: ROUTE_ALBUMS_LIST
}, {
  icon: 'mdi-account-star-outline',
  title: 'title.listUsers',
  route: ROUTE_USERS_SEARCH
}, {
  icon: 'mdi-school-outline',
  title: 'title.newLearnTitle',
  label: 'title.newLearnLabel',
  route: ROUTE_LEARN_PAGES
}, {
  icon: 'mdi-bell-outline',
  title: 'Notificações',
  route: ROUTE_NOTIFICATIONS
}, {
  icon: 'mdi-arrow-up-bold-circle-outline',
  title: 'title.newUpdatesLabel',
  label: 'title.newUpdatesImprovements',
  route: ROUTE_UPDATES_LIST
}]
