<template>
  <v-app>
    <Menu
      v-if="!isPreview"
      :session="session"
    />

    <Header
      v-if="!isPreview"
      :token="token"
    />

    <v-main>
      <v-container fluid>
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            md11
            xl10
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <router-view v-if="token" />
            </transition>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import pkg from '../../../package.json'
import { getToken, getSession } from '@/utils'
import Menu from '@/components/Menu'
import Header from '@/components/Header'
import PageBus from '@/components/page/PageBus'

export default {
  components: {
    Menu,
    Header
  },

  data () {
    return {
      version: pkg.version,
      token: null,
      stream: {},
      session: {}
    }
  },

  mounted () {
    const vm = this
    vm.loadSession()
    vm.windowPlayer = null
    vm.interval = setInterval(() => vm.loadSession(), 1000 * 60 * 5)
    window.addEventListener('message', vm.onPopupMessage, true)

    PageBus.$on('audioPlay', vm.onAudioPlay)
    PageBus.$on('playlist', vm.openPlayer)
  },

  beforeDestroy () {
    const vm = this
    PageBus.$off('audioPlay', vm.onAudioPlay)
    PageBus.$off('playlist', vm.openPlayer)
    window.removeEventListener('message', vm.onPopupMessage, true)
    if (vm.interval) clearInterval(vm.interval)
    const canCloseConfig = vm.windowPlayer && !vm.windowPlayer.closed
    if (canCloseConfig) vm.windowPlayer.close()
    vm.windowPlayer = null
  },

  computed: {
    isPreview () {
      return ['true', 't', 'yes', 'y', '1'].includes(this.$route?.query.preview)
    }
  },

  methods: {
    onAudioPlay (audio) {
      const vm = this
      vm.openPlayer([audio])
    },

    onPopupMessage (event) {
      const vm = this
      if (event.origin !== window.location.origin) return
      const config = parseJson(event.data)
      if (!config) return
      if (config.configId !== vm.itemId) return

      if (config.action === 'isOpened') return event.source.postMessage(JSON.stringify({ action: 'update', item: { id: vm.itemId, component: vm.itemType, data: vm.data } }), event.origin)
      if (config.action === 'update') return vm.updateData(config.item.data)
    },

    play (playlist) {
      const vm = this
      vm.windowPlayer.focus()
      vm.windowPlayer.postMessage(JSON.stringify({
        action: 'playAudios',
        audios: playlist
      }), window.location.origin)
    },

    openPlayer (playlist) {
      const vm = this
      const hasWindowPlayer = vm.windowPlayer && !vm.windowPlayer.closed

      if (hasWindowPlayer) return vm.play(playlist)
      vm.windowPlayer = window.open('/player', 'Player DMC Vivo', 'width=500,height=450')

      const onInitialize = (event) => {
        if (event.origin !== window.location.origin) return
        const config = parseJson(event.data)
        if (config?.action !== 'playerOpened') return
        window.removeEventListener('message', onInitialize, true)
        vm.play(playlist)
      }

      window.addEventListener('message', onInitialize, true)
    },

    loadSession () {
      const vm = this

      getToken().then((token) => (
        getSession().then((session) => (
          Object.assign(vm, { session, token })
        ))
      ))
    }
  }
}

const parseJson = (str) => {
  if (!str) return null

  try {
    return JSON.parse(str)
  } catch {
    return null
  }
}

</script>
