export const STATUS_SAVED = 'STATUS_SAVED'
export const STATUS_ERROR = 'STATUS_ERROR'
export const STATUS_LOADED = 'STATUS_LOADED'
export const STATUS_REJECT = 'STATUS_REJECT'
export const STATUS_LOADING = 'STATUS_LOADING'

export const ACTION_USER_MFA = 'ACTION_USER_MFA'
export const ACTION_USER_CODE = 'ACTION_USER_CODE'
export const ACTION_USER_LOGIN = 'ACTION_USER_LOGIN'
export const ACTION_USER_FORGOT = 'ACTION_USER_FORGOT'
export const ACTION_USER_CREATE = 'ACTION_USER_CREATE'
export const ACTION_USER_LOGOUT = 'ACTION_USER_LOGOUT'
export const ACTION_USER_RECOVERY = 'ACTION_USER_RECOVERY'
export const ACTION_USER_FORCE_NEW_PASSWORD = 'ACTION_USER_FORCE_NEW_PASSWORD'

export const ROUTE_REPORT_AUDIOS = 'ROUTE_REPORT_AUDIOS'
export const ROUTE_REPORT_SEARCH = 'ROUTE_REPORT_SEARCH'
export const ROUTE_REPORT_ACCESS = 'ROUTE_REPORT_ACCESS'
export const ROUTE_REPORT_REVIEWS = 'ROUTE_REPORT_REVIEWS'
export const ROUTE_REPORT_NAVIGATION = 'ROUTE_REPORT_NAVIGATION'

export const ACTION_AUDIO_VIEW = 'ACTION_AUDIO_VIEW'
export const ACTION_AUDIO_SAVE = 'ACTION_AUDIO_SAVE'
export const ACTION_AUDIO_LOAD_API = 'ACTION_AUDIO_LOAD_API'
export const ACTION_AUDIO_LIST = 'ACTION_AUDIO_LIST'
export const ACTION_AUDIO_REMOVE = 'ACTION_AUDIO_REMOVE'
export const ACTION_AUDIO_SEARCH = 'ACTION_AUDIO_SEARCH'
export const ACTION_AUDIO_LIST_CLEAR = 'ACTION_AUDIO_LIST_CLEAR'

export const ACTION_USERS_SEARCH = 'ACTION_USERS_SEARCH'

export const MUTATION_ROUTE = 'MUTATION_ROUTE'
export const MUTATION_USER_LOGOUT = 'MUTATION_USER_LOGOUT'
export const MUTATION_RESEND_CODE = 'MUTATION_RESEND_CODE'

export const ROUTE_AUDIOS_NEW = 'ROUTE_AUDIOS_NEW'
export const ROUTE_AUDIO_REVIEW = 'ROUTE_AUDIO_REVIEW'
export const ROUTE_USERS_SEARCH = 'ROUTE_USERS_SEARCH'

export const ROUTE_AUDIOS_APPROVED = 'ROUTE_AUDIOS_APPROVED'
export const ROUTE_AUDIOS_REVIEW = 'ROUTE_AUDIOS_REVIEW'
export const ROUTE_AUDIOS_REVIEW2 = 'ROUTE_AUDIOS_REVIEW2'
export const ROUTE_AUDIOS_APPROVING = 'ROUTE_AUDIOS_APPROVING'
export const ROUTE_AUDIOS_EXAM = 'ROUTE_AUDIOS_EXAM'

export const ROUTE_ALBUMS_NEW = 'ROUTE_ALBUMS_NEW'
export const ROUTE_ALBUMS_LIST = 'ROUTE_ALBUMS_LIST'
export const ROUTE_ALBUMS_EDIT = 'ROUTE_ALBUMS_EDIT'
export const ROUTE_ALBUMS_VIEW = 'ROUTE_ALBUMS_VIEW'

export const CODE_AUDIOS_APPROVED = 1
export const CODE_AUDIOS_APPROVING = 2
export const CODE_AUDIOS_EXAM = 3
export const CODE_AUDIOS_FIRST_REVIEW = 4
export const CODE_AUDIOS_WITHOUT_REVIEW = 0

export const ROUTE_UPDATES_LIST = 'ROUTE_UPDATES_LIST'
export const ROUTE_UPDATES_NEW = 'ROUTE_UPDATES_NEW'
export const ROUTE_UPDATES_VIEW = 'ROUTE_UPDATES_VIEW'
export const ROUTE_UPDATES_EDIT = 'ROUTE_UPDATES_EDIT'

export const ROUTE_DASHBOARD_CHARTS = 'ROUTE_DASHBOARD_CHARTS'

export const ROUTE_AUTH_MFA = 'ROUTE_AUTH_MFA'
export const ROUTE_AUTH_LOGIN = 'ROUTE_AUTH_LOGIN'
export const ROUTE_AUTH_INVITE = 'ROUTE_AUTH_INVITE'
export const ROUTE_AUTH_FORGOT = 'ROUTE_AUTH_FORGOT'
export const ROUTE_AUTH_CREATE = 'ROUTE_AUTH_CREATE'
export const ROUTE_AUTH_RECOVERY = 'ROUTE_AUTH_RECOVERY'
export const ROUTE_REMOVE_ACCOUNT = 'ROUTE_REMOVE_ACCOUNT'
export const ROUTE_AUTH_FORCE_NEW_PASSWORD = 'ROUTE_AUTH_FORCE_NEW_PASSWORD'

export const REVISOR_CODE = 16
export const DIRIGENTE_CODE = 17
export const SUPERVISOR_CODE = 18
export const PESQUISADOR_CODE = 7
export const ADMINISTRADOR_CODE = 21

// export const LEARN_PAGES_ADMIN_CODE = 22
export const LEARN_PAGES_ARTWORK_CODE = 23
export const LEARN_PAGES_REVIEW_CODE = 24

export const ROUTE_LEARN_PAGES = 'ROUTE_LEARN_PAGES'
export const ROUTE_LEARN_PAGES_NEW = 'ROUTE_LEARN_PAGES_NEW'
export const ROUTE_LEARN_PAGES_VIEW = 'ROUTE_LEARN_PAGES_VIEW'
export const ROUTE_LEARN_PAGES_EDIT = 'ROUTE_LEARN_PAGES_EDIT'
export const ROUTE_NOTIFICATIONS = 'ROUTE_NOTIFICATIONS'
