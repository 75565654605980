import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import updates from './updates'
import albums from './albums'
import learnPages from './learnPages'
import searchUsers from './searchUsers'
import searchAudios from './searchAudios'
import playlistAudios from './playlistAudios'
import VuexMiddlewares from '@/plugins/VuexMiddlewares'
import VuexRouterState from '@/plugins/VuexRouterState'

Vue.use(Vuex)

const modules = {
  auth,
  albums,
  updates,
  learnPages,
  searchUsers,
  searchAudios,
  playlistAudios
}

const plugins = [
  VuexMiddlewares(modules),
  VuexRouterState()
]

export default new Vuex.Store({ plugins, modules })
