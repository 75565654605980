<template>
  <v-app-bar
    color="primary"
    dense
    fixed
    dark
    app
    flat
    clipped-left
    :height="50"
  >
    <v-spacer />

    <v-toolbar-title>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <span>
            <Logo
              class="mt-2"
              v-on="on"
            />
          </span>
        </template>
        <span>Departamento de Memória e Comunicação</span>
      </v-tooltip>
    </v-toolbar-title>

    <v-spacer />

    <v-toolbar-items style="position: absolute; right: 0">
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            text
            v-on="on"
            style="text-transform: lowercase; margin: 0; padding: 0; min-width: 45px"
          >
            <small>v{{ version }}</small>
          </v-btn>
        </template>
        <span>{{ 'btn.version'.translate() }}</span>
      </v-tooltip>

      <v-tooltip
        v-if="token"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            icon
            v-on="on"
            @click="userLogout"
          >
            <v-icon dense>
              mdi-exit-to-app
            </v-icon>
          </v-btn>
        </template>
        <span>{{ 'btn.exit'.translate() }}</span>
      </v-tooltip>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import pkg from '../../package.json'
import Logo from '@/icons/logo.svg'
import { ACTION_USER_LOGOUT } from '@/constants'

export default {
  components: {
    Logo
  },

  props: {
    token: {}
  },

  data () {
    return {
      version: pkg.version
    }
  },

  methods: {
    userLogout () {
      this.$store.dispatch(ACTION_USER_LOGOUT)
    }
  }
}
</script>
