import { requests } from '@/plugins/Amplify'

import {
  STATUS_ERROR,
  STATUS_SAVED,
  STATUS_LOADED,
  STATUS_REJECT,
  STATUS_LOADING,

  ACTION_AUDIO_SEARCH,

  ACTION_AUDIO_VIEW,

  MUTATION_USER_LOGOUT,

  ACTION_AUDIO_LOAD_API,
  ACTION_AUDIO_SAVE,
  ACTION_AUDIO_REMOVE
} from '@/constants'

const MUTATION_VIEW_ERROR = 'MUTATION_VIEW_ERROR'
const MUTATION_VIEW_LOADING = 'MUTATION_VIEW_LOADING'
const MUTATION_VIEW_SUCCESS = 'MUTATION_VIEW_SUCCESS'

const MUTATION_AUDIOS_ERROR = 'MUTATION_AUDIOS_ERROR'
const MUTATION_AUDIOS_LOADING = 'MUTATION_AUDIOS_LOADING'
const MUTATION_AUDIOS_SUCCESS = 'MUTATION_AUDIOS_SUCCESS'

const MUTATION_REVIEW_ERROR = 'MUTATION_REVIEW_ERROR'
const MUTATION_REVIEW_LOADED = 'MUTATION_REVIEW_LOADED'
const MUTATION_REVIEW_LOADING = 'MUTATION_REVIEW_LOADING'
const MUTATION_REVIEW_SUCCESS = 'MUTATION_REVIEW_SUCCESS'

const MUTATION_REVIEWS = 'MUTATION_REVIEWS'
const MUTATION_REVIEWS_ADD = 'MUTATION_REVIEWS_ADD'
const MUTATION_REVIEWS_REJECT = 'MUTATION_REVIEWS_REJECT'
const MUTATION_REVIEWS_SAVED = 'MUTATION_REVIEWS_SAVED'
const MUTATION_REVIEWS_CANCELED = 'MUTATION_REVIEWS_CANCELED'

const MUTATION_AUDIO_PAUSE = 'MUTATION_AUDIO_PAUSE'
const MUTATION_AUDIO_ENDED = 'MUTATION_AUDIO_ENDED'
const MUTATION_AUDIO_LISTEN = 'MUTATION_AUDIO_LISTEN'

const MUTATION_SOCKET_CONNECTED = 'MUTATION_SOCKET_CONNECTED'
const MUTATION_SOCKET_DISCONNECTED = 'MUTATION_SOCKET_DISCONNECTED'

const resetStore = () => ({
  stream: null,
  socket: false,

  reviews: {},
  reviewId: null,
  reviewStatus: null,
  reviewData: {},

  viewData: {},
  viewStatus: null,

  rows: [],
  count: 0,
  params: null,
  status: null
})

const loadAudios = async (commit, params) => {
  commit(MUTATION_AUDIOS_LOADING, params)

  try {
    const data = await requests.getAudios({ limit: 12, ...params })
    commit(MUTATION_AUDIOS_SUCCESS, data)
    return data
  } catch (error) {
    commit(MUTATION_AUDIOS_ERROR, error)
  }
}

export default {
  state: resetStore(),

  actions: {
    [ACTION_AUDIO_SEARCH]: async ({ commit }, params) => {
      loadAudios(commit, params)
    },

    [ACTION_AUDIO_LOAD_API]: async ({ commit, state }, audioId) => {
      try {
        const reviewId = audioId ?? state.reviewId
        commit(MUTATION_REVIEW_LOADING, reviewId)
        const data = await requests.getAudio(reviewId)
        commit(MUTATION_REVIEW_LOADED, data)
      } catch (error) {
        commit(MUTATION_REVIEW_ERROR, error)
      }
    },

    [ACTION_AUDIO_VIEW]: async ({ commit }, audioId) => {
      commit(MUTATION_VIEW_LOADING, audioId)

      try {
        const data = await requests.getAudio(audioId)
        commit(MUTATION_VIEW_SUCCESS, data)
      } catch (error) {
        commit(MUTATION_VIEW_ERROR, error)
      }
    },

    [ACTION_AUDIO_SAVE]: async ({ commit }, metadata) => {
      try {
        commit(MUTATION_REVIEW_LOADING)
        const data = await requests.setMetadata(metadata.id, metadata)
        commit(MUTATION_REVIEW_SUCCESS, data)
      } catch (error) {
        commit(MUTATION_REVIEW_ERROR, error)
      }
    },

    [ACTION_AUDIO_REMOVE]: async ({ commit }, audioId) => {
      try {
        commit(MUTATION_REVIEW_LOADING)
        const data = await requests.deleteAudio(audioId)
        commit(MUTATION_REVIEW_SUCCESS, data)
      } catch (error) {
        commit(MUTATION_REVIEW_ERROR, error)
      }
    }
  },

  mutations: {
    [MUTATION_SOCKET_CONNECTED]: (state) => {
      state.socket = true
    },

    [MUTATION_SOCKET_DISCONNECTED]: (state) => {
      state.socket = false
    },

    [MUTATION_AUDIO_LISTEN]: (state, audio) => {
      state.stream = { ...audio, action: 'PLAY' }
    },

    [MUTATION_AUDIO_PAUSE]: (state) => {
      state.stream = { ...state.stream, action: 'PAUSE' }
    },

    [MUTATION_AUDIO_ENDED]: (state) => {
      state.stream = { ...state.stream, action: 'ENDED' }
    },

    [MUTATION_AUDIOS_LOADING]: (state, params) => {
      Object.assign(state, { params, status: STATUS_LOADING })
    },

    [MUTATION_AUDIOS_SUCCESS]: (state, data) => {
      const { count } = data

      const rows = data.rows.map((audio) => {
        let idStatus = audio.id
        if (state.reviews[audio.id]) idStatus = `${audio.id}${state.reviews[audio.id].action}`
        return { ...audio, idStatus }
      })

      Object.assign(state, { rows, count, status: STATUS_LOADED })
    },

    [MUTATION_AUDIOS_ERROR]: (state) => {
      Object.assign(state, { status: STATUS_ERROR })
    },

    [MUTATION_USER_LOGOUT]: (state) => {
      Object.assign(state, resetStore())
    },

    [MUTATION_REVIEW_LOADING]: (state, reviewId) => {
      Object.assign(state, {
        reviewId: reviewId || state.reviewId,
        reviewData: {},
        reviewStatus: STATUS_LOADING
      })
    },

    [MUTATION_REVIEWS_REJECT]: (state) => {
      Object.assign(state, {
        reviewId: null,
        reviewData: {},
        reviewStatus: STATUS_REJECT
      })
    },

    [MUTATION_REVIEW_LOADED]: (state, reviewData) => {
      Object.assign(state, {
        stream: { ...reviewData, action: 'PLAY' },
        reviewData,
        reviewStatus: STATUS_LOADED
      })
    },

    [MUTATION_REVIEW_SUCCESS]: (state, reviewData) => {
      Object.assign(state, {
        reviewData,
        reviewStatus: STATUS_SAVED
      })
    },

    [MUTATION_REVIEW_ERROR]: (state) => {
      Object.assign(state, {
        reviewStatus: STATUS_ERROR
      })
    },

    [MUTATION_VIEW_LOADING]: (state) => {
      Object.assign(state, {
        viewData: {},
        viewStatus: STATUS_LOADING
      })
    },

    [MUTATION_VIEW_SUCCESS]: (state, viewData) => {
      Object.assign(state, {
        viewData,
        viewStatus: STATUS_LOADED
      })
    },

    [MUTATION_VIEW_ERROR]: (state) => {
      Object.assign(state, {
        viewData: {},
        viewStatus: STATUS_ERROR
      })
    },

    [MUTATION_REVIEWS]: (state, { reviews }) => {
      state.reviews = reviews.reduce((group, review) => ({ ...group, [review.id]: review }), {})
    },

    [MUTATION_REVIEWS_ADD]: (state, review) => {
      state.reviews[review.id] = review
      const index = state.rows.findIndex(({ id }) => id === review.id)
      if (index === -1) return
      state.rows.splice(index, 1, { ...state.rows[index], idStatus: `${review.id}${review.action}` })
    },

    [MUTATION_REVIEWS_SAVED]: (state, review) => {
      delete state.reviews[review.id]
      const index = state.rows.findIndex(({ id }) => id === review.id)
      if (index === -1) return
      state.rows.splice(index, 1, { ...review, idStatus: review.id })
    },

    [MUTATION_REVIEWS_CANCELED]: (state, review) => {
      delete state.reviews[review.id]
      const index = state.rows.findIndex(({ id }) => id === review.id)
      if (index === -1) return
      state.rows.splice(index, 1, { ...state.rows[index], idStatus: review.id })
    }
  }
}
